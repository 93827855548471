"use client";
import { useEffect } from "react";
import { redirect } from "next/navigation";

export default function Page() {
  useEffect(() => {
    redirect("/home");
  }, []);

  return <p>Redirect to home...</p>;
}
